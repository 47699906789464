.sortion-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 250px;
  padding: 0px;
  margin: 0 auto;
}

/* [type='radio'] {
  display: none;
} */
