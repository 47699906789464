.PostsCards {
  width: 700px;
  height: auto;
  min-height: 770px;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 1px rgb(94, 94, 94);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.PostsCards p {
  font-weight: 300;
}
.upper-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 120px;
  background: linear-gradient(to left, rgb(170, 212, 221), #d5eafa);
  text-align: center;
}

.username-container {
  display: flex;
  transform: translate(-10px, 40px);
  text-align: left;
  justify-content: center;
}

.username-container p {
  font-size: 20px;
  font-weight: lighter;
}

.image-container {
  background-color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 5px;
  transform: translate(290px, 60px);
}

.image-container img {
  padding: 5px;
  border-radius: 50%;
  border: 2px solid #a3d4f7;
}

.mid-container {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
}

.title-container h3 {
  font-weight: bolder;
}

.voting-container {
  margin-top: -5px;
}

.content-container {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
}

.content-container p {
  font-size: 16px;
  font-weight: lighter;
}

.comment-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.form-group {
  margin-right: 20px;
}

.post-comment {
  display: inline-flex;
  margin: 10px auto;
  width: 100%;
}

.post-comment img.profile-photo-sm {
  margin-right: 10px;
}

.post-comment .form-control {
  height: 30px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: 7px 0;
  min-width: 0;
}

img.profile-photo-sm {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.post-comments-container {
  font-size: 14px;
  font-weight: 300;
}

.comments-label-container {
  font-size: 18px;
  font-weight: 400;
}
