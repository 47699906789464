.views-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Titillium Web', sans-serif;
}

.btn-primary {
  background-color: #a3d4f7 !important;
  border-color: #a3d4f7 !important;
  color: rgb(47, 47, 47);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #c1e5ff !important;
}

.views-container p {
  font-size: 9;
  font-weight: lighter;
}
